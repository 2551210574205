import Header from './components/Header';
import Footer from './components/Footer';
import NavigationHeader from './components/NavigationHeader';
import ReactNotification from 'react-notifications-component';
import 'react-notifications-component/dist/theme.css'

/**
 * Component used for putting the page together between the various elements
 * @class App
 */
function App() {
  return (
    <div className="App">

      <ReactNotification />

      <Header />

      <NavigationHeader />

      <Footer />

    </div>
  );
}

export default App;
