import Form from 'react-bootstrap/Form';
import { createUseStyles } from 'react-jss'

/**
 * Local css styles
 * @constant {object}
 */
const useStyles = createUseStyles({
    errorMessage:
    {
        marginLeft: '2vw',
        marginTop: '1vh',
        color: 'red'
    },
    errorMessageChangeMargin:
    {
        marginTop: '-1vh',
        marginLeft: '2vw',
        color: 'red'
    },
    myForm:
    {
        margin: '2vw',
        width: '25vw',
        marginBottom: '0px'
    },
    myLowerForm:
    {
        marginTop: '1vh',
        margin: '2vw',
        width: '25vw',
        marginBottom: '0px'
    },
    myLastInputForm:
    {
        marginTop: '1vh',
        margin: '2vw',
        width: '25vw',
        marginBottom: '20px'
    }
});


/**
 * Input form subcomponent for every field which is not a flag
 * @class GeneralInputForm
 */
const GeneralInputForm = ({ formObj }) => {

    /** @member {object} - Class styles */
    const classes = useStyles();

    return (
        <>
            <Form.Label className={classes.myForm} style={{ fontWeight: 'bold' }}>Codice Macchina</Form.Label>
            <Form.Control
                className={classes.myLowerForm}
                type="text"
                name="serial"
                onBlur={formObj.handleBlur}
                value={formObj.values.serial}
                onChange={formObj.handleChange}
            />
            {formObj.touched.serial && formObj.errors.serial ? (
                <div className={classes.errorMessage}>{formObj.errors.serial}</div>
            ) : null}
            <Form.Label className={classes.myForm} style={{ fontWeight: 'bold' }}>Cliente</Form.Label>
            <Form.Control
                className={classes.myLowerForm}
                type="text"
                name="note"
                onBlur={formObj.handleBlur}
                value={formObj.values.note}
                onChange={formObj.handleChange}
            />
            <Form.Label className={classes.myForm} style={{ fontWeight: 'bold' }} >Tipologia Codice</Form.Label>

            <Form.Group>
                <Form.Control as="select"
                    className={classes.myLastInputForm}
                    value={formObj.values.type}
                    onChange={formObj.handleChange}
                    style={{ width: '25vw' }}
                    onBlur={formObj.handleBlur}
                    name="type">
                    <option value="">Seleziona tipo licenza</option>
                    <option value="1">Usb</option>
                    <option value="2">Activation key</option>
                </Form.Control>
                {formObj.touched.type && formObj.errors.type ? (
                    <div className={classes.errorMessageChangeMargin}>{formObj.errors.type}</div>
                ) : null}
            </Form.Group>

            <Form.Label className={classes.myLastInputForm} style={{ fontWeight: 'bold' }} >Impostazione flag</Form.Label>
        </>
    );
}

export default GeneralInputForm;