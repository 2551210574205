import React, { useState, useEffect } from 'react';
import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card';
import { createUseStyles } from 'react-jss';
import ListGroup from 'react-bootstrap/ListGroup';
import Form from 'react-bootstrap/Form';
import {
    BsChevronDown
} from 'react-icons/bs';
import {
    BsChevronUp
} from 'react-icons/bs';

/**
 * Local css styles
 * @constant {object}
 */
const useStyles = createUseStyles({
    myAccordion: {
        width: '50vw'
    },
    myFormLabel:
    {
        width: '25vw'
    },
    myFormcheck:
    {
        marginLeft: '5vw'
    }
});

/**
 * Component used in order to display the workstation flags.
 * 
 * @param {object} formObj is the form object get by InsertLicence form 
 * @class CollapsibleWorkstation
 */
const CollapsibleWorkstation = ({ formObj }) => {

    /** @member {boolean} - expanded mode toggle */
    const [expanded, setExpanded] = useState(false);

    /** @member {object} - Class styles */
    const classes = useStyles();

    // handling accordion
    useEffect(() => {

    }, [expanded]);

    const selectedAccordion = () => {
        setExpanded(!expanded);
    }

    /**
     * change icon on expand event
     */
    function iconChange() {
        if (expanded) {
            return (<BsChevronUp style={{ marginRight: '10px' }} />);
        } else {
            return (<BsChevronDown style={{ marginRight: '10px' }} />);
        }
    }

    return (
        <Accordion onSelect={selectedAccordion} >
            <Card>
                <Card.Header variant="primary" >
                    <Accordion.Toggle style={{ border: '1px solid #D3D3D3', backgroundColor: 'rgba(0, 0, 0, 0)', color: 'black' }} as={Card.Header} eventKey="0">
                        {iconChange()}
                        Workstation Flags
                    </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="0">
                    <Card.Body>
                        <ListGroup.Item className={classes.myAccordion}  >
                            <Form.Label className={classes.myFormLabel}>
                                Licenza DCC Workstation
                            </Form.Label>
                            <Form.Check className={classes.myFormcheck}
                                inline
                                type='checkbox'
                                name="flag8"
                                value={formObj.values.flag8}
                                onBlur={formObj.handleBlur}
                                onChange={formObj.handleChange}
                                checked={formObj.values.flag8} />
                        </ListGroup.Item>
                        <ListGroup.Item className={classes.myAccordion} >
                            <Form.Label className={classes.myFormLabel}>
                                Gestione mappe
                            </Form.Label>
                            <Form.Check className={classes.myFormcheck}
                                inline
                                type='checkbox'
                                name="flag9"
                                value={formObj.values.flag9}
                                onBlur={formObj.handleBlur}
                                onChange={formObj.handleChange}
                                checked={formObj.values.flag9} />
                        </ListGroup.Item>
                        <ListGroup.Item className={classes.myAccordion} >
                            <Form.Label className={classes.myFormLabel}>
                                Flag 10
                            </Form.Label>
                            <Form.Check className={classes.myFormcheck}
                                inline
                                type='checkbox'
                                name="flag10"
                                value={formObj.values.flag10}
                                onBlur={formObj.handleBlur}
                                onChange={formObj.handleChange}
                                checked={formObj.values.flag10} />
                        </ListGroup.Item >
                        <ListGroup.Item className={classes.myAccordion}  >
                            <Form.Label className={classes.myFormLabel}>
                                Flag 11
                            </Form.Label>
                            <Form.Check className={classes.myFormcheck}
                                inline
                                type='checkbox'
                                name="flag11"
                                value={formObj.values.flag11}
                                onBlur={formObj.handleBlur}
                                onChange={formObj.handleChange}
                                checked={formObj.values.flag11} />
                        </ListGroup.Item >
                        <ListGroup.Item className={classes.myAccordion}  >
                            <Form.Label className={classes.myFormLabel}>
                                Flag 12
                            </Form.Label>
                            <Form.Check className={classes.myFormcheck}
                                inline
                                type='checkbox'
                                name="flag12"
                                value={formObj.values.flag12}
                                onBlur={formObj.handleBlur}
                                onChange={formObj.handleChange}
                                checked={formObj.values.flag12} />
                        </ListGroup.Item >
                        <ListGroup.Item className={classes.myAccordion}  >
                            <Form.Label className={classes.myFormLabel}>
                                Flag 13
                            </Form.Label>
                            <Form.Check className={classes.myFormcheck}
                                inline
                                type='checkbox'
                                name="flag13"
                                value={formObj.values.flag13}
                                onBlur={formObj.handleBlur}
                                onChange={formObj.handleChange}
                                checked={formObj.values.flag13} />
                        </ListGroup.Item >
                        <ListGroup.Item className={classes.myAccordion} >
                            <Form.Label className={classes.myFormLabel}>
                                Flag 14
                            </Form.Label>
                            <Form.Check className={classes.myFormcheck}
                                inline
                                type='checkbox'
                                name="flag14"
                                value={formObj.values.flag14}
                                onBlur={formObj.handleBlur}
                                onChange={formObj.handleChange}
                                checked={formObj.values.flag14} />
                        </ListGroup.Item >
                        <ListGroup.Item className={classes.myAccordion} >
                            <Form.Label className={classes.myFormLabel}>
                                Flag 15
                            </Form.Label>
                            <Form.Check
                                className={classes.myFormcheck}
                                inline
                                type='checkbox'
                                name="flag15"
                                value={formObj.values.flag15}
                                onBlur={formObj.handleBlur}
                                onChange={formObj.handleChange}
                                checked={formObj.values.flag15} />
                        </ListGroup.Item >
                    </Card.Body >
                </Accordion.Collapse >
            </Card >
        </Accordion >
    );
}

export default CollapsibleWorkstation;