import { notificationTime } from './GlobalConfig';

/**
 * @file This file contains all the components that allow you to manage notifications to the user
 */


/**
 * Bulder for generate a notification config object
 * @exports setNotificationObject
 * @function setNotificationObject 
 * @param {string} title - Notification title
 * @param {*} message - Body message
 * @param {*} type - Of message
 * @returns  notification config object
 */
export const setNotificationObject = (title, message, type) => {

    var res = {
        title,
        message,
        type,
        insert: "top",
        container: "top-center",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
            duration: notificationTime,
            onScreen: true
        }
    };

    return res;
}

// LOGIN
// ok or error

const okString = "Completato";
const errorString = "Errore";

// color

const successString = "success";
const dangerString = "danger";

// login strings

const loginErrorString = "Il login non è andato a buon fine";
const loginSuccessString = "Il login è eseguito correttamente"

// login expired

const loginExpiredString = "La sessione web è scaduta (durata 1 giorno)";

// INSERT LICENCE

// insert strings

const insertErrorString = "L'inserimento non è andato a buon fine";
const insertSuccessString = "L'inserimento è stato eseguito correttamente";

// download 

const downloadSuccessString = "il download è andato a buon fine";
const downloadErrorString = "il download ha incontrato un errore";


// delete 

const licenceSuccessString = "la licenza è stata cancellata correttamente";
const licenceErrorString = "la licenza non è stata eliminata";

/**
 * I18n object
 * @exports notificationMessage
 * @constant notificationMessage
 * @type {object}
 */
export const notificationMessage = {
    okString,
    errorString,
    successString,
    dangerString,
    loginErrorString,
    loginSuccessString,
    loginExpiredString,
    insertErrorString,
    insertSuccessString,
    downloadSuccessString,
    downloadErrorString,
    licenceSuccessString,
    licenceErrorString
};