import { useContext } from 'react';
import LoginForm from "../components/LoginForm";
import { createUseStyles } from 'react-jss';
import { Formik } from 'formik';
import axios from 'axios';
import * as Yup from 'yup';
import { useHistory } from "react-router-dom";
import { baseApiUrl, userApiUrl } from '../globals/ApiConfig';
import { axiosTimeout } from '../globals/GlobalConfig';
import {
    StatusCodes,
} from 'http-status-codes';
import { UserContext } from '../context/UserContext';
import { store } from 'react-notifications-component';
import { setNotificationObject, notificationMessage } from '../globals/NotificationConfig';

/**
 * Local css styles
 * @constant {object}
 */
const useStyles = createUseStyles({
    myLogin:
    {
        height: '78vh',
        overflowY: 'auto',
        display: 'flex',
        justifyContent: 'center',
    },
});

/**
 * Login page
 * @class Login
 */
const Login = () => {

    /** @member {object} - Class styles */
    const classes = useStyles();

    /** @member {boolean} - Check if user login */
    const { setIsLoggedIn } = useContext(UserContext);

    /** @member {object} - History  */
    const history = useHistory();

    /** @member {object} - from validator  */
    const validationSchema = Yup.object().shape({
        email: Yup.string()
            .email("Inserire un indirizzo valido")
            .max(100, "L'email deve essere meno di 100 caratteri")
            .required("Inserire una email"),
        password: Yup.string()
            .required('Non è stata fornita una password')
            .min(8, 'La password deve essere almeno 8 caratteri')
    });

    /**  
     * Check validation then sumbit form
     * @param {object} values - object whit email and password
     */
    const submit = (values) => {
        const params = new URLSearchParams()
        params.append('email', values.email);
        params.append('password', values.password);

        axios.post(baseApiUrl + userApiUrl, params, { timeout: axiosTimeout })
            .then((result) => {
                if (result !== undefined && StatusCodes.OK === result.status) {
                    if (result.data !== null && result.data.user !== null && result.data.user.token !== null && result.data.user.token !== undefined) {
                        const notification = setNotificationObject(notificationMessage.okString, notificationMessage.loginSuccessString, notificationMessage.successString);
                        store.addNotification(notification);
                        localStorage.setItem("token", result.data.user.token);
                        localStorage.setItem("email", result.data.user.email);
                        setIsLoggedIn(true);
                        history.push("/list");
                    } else {
                        const notification = setNotificationObject(notificationMessage.errorString, notificationMessage.loginErrorString, notificationMessage.dangerString);
                        store.addNotification(notification);
                    }
                } else {
                    const notification = setNotificationObject(notificationMessage.errorString, notificationMessage.loginErrorString, notificationMessage.dangerString);
                    store.addNotification(notification);
                }
            })
            .catch((err) => {
                const notification = setNotificationObject(notificationMessage.errorString, notificationMessage.loginErrorString, notificationMessage.dangerString);
                store.addNotification(notification);
            })
    }

    return (

        <Formik
            initialValues={{ email: "", password: "" }}
            // Hooks up our validationSchema to Formik 
            validationSchema={validationSchema}
            onSubmit={submit}
        >
            {/* Callback function containing Formik state and helpers that handle common form actions */}
            {
                ({ values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting }) => {

                    const formObj = {
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        isSubmitting
                    };

                    return (
                        <div className={classes.myLogin}>
                            <div className={classes.loginContainer}>
                                <LoginForm formObj={formObj} />
                            </div>
                        </div>
                    );
                }
            }
        </Formik >
    );
}

export default Login;