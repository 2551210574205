import Form from 'react-bootstrap/Form';
import { useContext, useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import axios from 'axios';
import { createUseStyles } from 'react-jss';
import CollapsibleServer from '../components/CollapsibleServer';
import CollapsibleWorkstation from '../components/CollapsibleWorkstation';
import CollapsibleVideowall from '../components/CollapsibleVideowall';
import GeneralInputForm from '../components/GeneralInputForm';
import { LicenceContext } from '../context/LicenceContext';
import { useParams } from "react-router-dom";
import { Formik } from 'formik';
import * as Yup from 'yup';
import { store } from 'react-notifications-component';
import { setNotificationObject, notificationMessage } from '../globals/NotificationConfig';
import { baseApiUrl, licenceApiUrl } from '../globals/ApiConfig';
import { useHistory } from "react-router-dom";
import FileDownload from 'js-file-download';
import logout from '../globals/AuthenticationHandler';
import { UserContext } from '../context/UserContext';
import { tokenGenerate } from '../globals/TokenHandler'
import {
    StatusCodes,
} from 'http-status-codes';

/**
 * Local css styles
 * @constant {object}
 */
const useStyles = createUseStyles({
    myCollapsibleBody:
    {
        height: '78vh',
        overflowY: 'auto'
    },
    myBtn:
    {
        marginLeft: '2vw', marginTop: '2vh', marginBottom: '2vh'
    }
});

/**
 * Page used for creating and updating licences
 * @class InsertLicence
 */
const InsertLicence = () => {

    /** @member {boolean} - licence managing store */
    const [licence, setLicence] = useState([]);

    /** @member {object} - Class styles */
    const classes = useStyles();

    /** @member {object} - Retrive stored licence */
    const { serial } = useParams();

    /** @member {object} - Retrive filtered licence */
    const { filterLicence } = useContext(LicenceContext);

    /** @member {object} - History  */
    const history = useHistory();
    const { setIsLoggedIn } = useContext(UserContext)

    //set licence at startup
    useEffect(() => {
        if (serial !== null && serial !== undefined) {
            setLicence(filterLicence(serial));
        }
    }, []);


    /**
     * Sumbit dato to backend
     * @param {object} values - data to send 
     */
    const submit = (values) => {

        if (serial === null || serial === undefined) {

            const serialVal = values.serial;
            const token = tokenGenerate(localStorage.getItem('email'));
            const config = {
                headers: {
                    Authorization: `Bearer ${token}`,
                    email: localStorage.getItem('email')
                }
            };
            axios.post(baseApiUrl + licenceApiUrl,
                {
                    serial: values.serial,
                    type: values.type,
                    note: values.note,
                    flag0: values.flag0,
                    flag1: values.flag1,
                    flag2: values.flag2,
                    flag3: values.flag3,
                    flag4: values.flag4,
                    flag5: values.flag5,
                    flag6: values.flag6,
                    flag7: values.flag7,
                    flag8: values.flag8,
                    flag9: values.flag9,
                    flag10: values.flag10,
                    flag11: values.flag11,
                    flag12: values.flag12,
                    flag13: values.flag13,
                    flag14: values.flag14,
                    flag15: values.flag15,
                    flag16: values.flag16,
                    flag17: values.flag17,
                    flag18: values.flag18,
                    flag19: values.flag19,
                    flag20: values.flag20,
                    flag21: values.flag21,
                    flag22: values.flag22,
                    flag23: values.flag23,
                },
                config
            )
                .then((result) => {
                    if (StatusCodes.OK === result.status) {
                        // if there isn't a type in response == activation key
                        if (result.data.type === undefined || result.data.type === null) {
                            FileDownload(result.data, 'DCCPLicence.lic');
                        }
                        const notification = setNotificationObject(notificationMessage.okString, notificationMessage.insertSuccessString, notificationMessage.successString);
                        store.addNotification(notification);
                        history.push("/list");
                    } else {
                        const notification = setNotificationObject(notificationMessage.errorString, notificationMessage.insertErrorString, notificationMessage.dangerString);
                        store.addNotification(notification);
                    }
                })
                .catch((err) => {
                    if (err.response.status === StatusCodes.FORBIDDEN) {
                        // logout
                        logout(setIsLoggedIn);
                    } else {
                        const notification = setNotificationObject(notificationMessage.errorString, notificationMessage.insertErrorString, notificationMessage.dangerString);
                        store.addNotification(notification);
                    }
                })
        } else {
            const serialVal = values.serial;
            const token = tokenGenerate(localStorage.getItem('email'));
            const config = {
                headers: {
                    Authorization: `Bearer ${token}`,
                    email: localStorage.getItem('email')
                }
            };
            axios.put(baseApiUrl + licenceApiUrl + serial,
                {
                    serial: values.serial,
                    type: values.type,
                    note: values.note,
                    flag0: values.flag0,
                    flag1: values.flag1,
                    flag2: values.flag2,
                    flag3: values.flag3,
                    flag4: values.flag4,
                    flag5: values.flag5,
                    flag6: values.flag6,
                    flag7: values.flag7,
                    flag8: values.flag8,
                    flag9: values.flag9,
                    flag10: values.flag10,
                    flag11: values.flag11,
                    flag12: values.flag12,
                    flag13: values.flag13,
                    flag14: values.flag14,
                    flag15: values.flag15,
                    flag16: values.flag16,
                    flag17: values.flag17,
                    flag18: values.flag18,
                    flag19: values.flag19,
                    flag20: values.flag20,
                    flag21: values.flag21,
                    flag22: values.flag22,
                    flag23: values.flag23,
                },
                config
            )
                .then((result) => {
                    if (StatusCodes.OK === result.status) {
                        if (result.data.type === undefined || result.data.type === null) {
                            FileDownload(result.data, 'DCCPLicence.lic');
                        }
                        const notification = setNotificationObject(notificationMessage.okString, notificationMessage.insertSuccessString, notificationMessage.successString);
                        store.addNotification(notification);
                        history.push("/list");
                    } else {
                        const notification = setNotificationObject(notificationMessage.errorString, notificationMessage.insertErrorString, notificationMessage.dangerString);
                        store.addNotification(notification);
                    }
                })
                .catch((err) => {
                    if (err.response.status === StatusCodes.FORBIDDEN) {
                        logout(setIsLoggedIn);
                    } else {
                        const notification = setNotificationObject(notificationMessage.errorString, notificationMessage.insertErrorString, notificationMessage.dangerString);
                        store.addNotification(notification);
                    }
                })
        }
    }

    /**
     * @member {boolean} - Checked form
     */
    const validationSchema = Yup.object().shape({
        serial: Yup.string()
            .required("Inserire un seriale"),
        type: Yup.number().required("Inserire un tipo licenza").positive().integer()
    });

    return (
        <div className="InsertLicence">

            <div className={classes.myCollapsibleBody}>

                <Formik
                    enableReinitialize
                    initialValues={{
                        serial: licence ? licence.serial : null,
                        note: licence ? licence.note : null,
                        type: licence ? licence.type : null,
                        flag0: licence ? licence.flag0 : false,
                        flag1: licence ? licence.flag1 : false,
                        flag2: licence ? licence.flag2 : false,
                        flag3: licence ? licence.flag3 : false,
                        flag4: licence ? licence.flag4 : false,
                        flag5: licence ? licence.flag5 : false,
                        flag6: licence ? licence.flag6 : false,
                        flag7: licence ? licence.flag7 : false,
                        flag8: licence ? licence.flag8 : false,
                        flag9: licence ? licence.flag9 : false,
                        flag10: licence ? licence.flag10 : false,
                        flag11: licence ? licence.flag11 : false,
                        flag12: licence ? licence.flag12 : false,
                        flag13: licence ? licence.flag13 : false,
                        flag14: licence ? licence.flag14 : false,
                        flag15: licence ? licence.flag15 : false,
                        flag16: licence ? licence.flag16 : false,
                        flag17: licence ? licence.flag17 : false,
                        flag18: licence ? licence.flag18 : false,
                        flag19: licence ? licence.flag19 : false,
                        flag20: licence ? licence.flag20 : false,
                        flag21: licence ? licence.flag21 : false,
                        flag22: licence ? licence.flag22 : false,
                        flag23: licence ? licence.flag23 : false,
                    }}
                    // Hooks up our validationSchema to Formik 
                    validationSchema={validationSchema}
                    onSubmit={submit}
                >
                    {/* Callback function containing Formik state and helpers that handle common form actions */}
                    {
                        ({ values,
                            errors,
                            touched,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                            isSubmitting,
                            setFieldValue }) => {

                            const formObj = {
                                values,
                                errors,
                                touched,
                                handleChange,
                                handleBlur,
                                handleSubmit,
                                isSubmitting,
                                setFieldValue
                            };

                            return (
                                <>
                                    <Form onSubmit={formObj.handleSubmit}>
                                        <GeneralInputForm
                                            formObj={formObj}
                                        />

                                        <CollapsibleServer
                                            formObj={formObj}
                                        />
                                        <CollapsibleWorkstation
                                            formObj={formObj}
                                        />
                                        <CollapsibleVideowall
                                            formObj={formObj}
                                        />

                                        <Button type="submit" variant="dark" className={classes.myBtn}>
                                            {serial ? "Modifica licenza" : "Crea licenza"}</Button>
                                    </Form>
                                </>
                            );
                        }
                    }
                </Formik >

            </div>

        </div>
    );
}

export default InsertLicence;