import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { createUseStyles } from 'react-jss'
import useMedia from '../hooks/useMedia'

/**
 * Local css styles
 * @constant {object}
 */
const useStyles = createUseStyles({
    formContainer:
    {
        width: '30vw',
    },
    myForm:
    {
        margin: '20px',
        marginTop: '0px',
        marginBottom: '10px',
        width: '25vw'
    },
    myFormButton:
    {
        margin: '20px',
        marginTop: '3vh',
        marginBottom: '0px',
        width: '25vw',
        backgroundColor: '#2F4F4F',
        borderRadius: '1vw',
        color: 'white',
    },
    headerBox:
    {
        marginTop: '10vh',
        justifyContent: 'center'
    },
    loginBoxSmall:
    {
        marginTop: '1vh',
        paddingRight: '10vw',
        paddingLeft: '10vw',
        paddingBottom: '10vh',
        paddingTop: '10vh',
        borderRadius: '2vw',
        border: '2px solid grey',
        backgroundImage: 'linear-gradient(45deg, white, #B0B0B0)'
    },
    loginBoxMedium:
    {
        marginTop: '1vh',
        paddingRight: '7vw',
        paddingLeft: '7vw',
        paddingBottom: '10vh',
        paddingTop: '10vh',
        borderRadius: '2vw',
        border: '2px solid grey',
        backgroundImage: 'linear-gradient(45deg, white, #B0B0B0)'
    },
    loginBoxLarge:
    {
        marginTop: '1vh',
        paddingRight: '5vw',
        paddingLeft: '5vw',
        paddingBottom: '10vh',
        paddingTop: '10vh',
        borderRadius: '2vw',
        border: '2px solid grey',
        backgroundImage: 'linear-gradient(45deg, white, #B0B0B0)'
    },
    errorMessage:
    {
        marginTop: '1vh',
        color: 'red'
    }
});

/**
 * LoginForm, this is used by the login.js view
 *
 * @component
 * @example
 * const formObj = {...}
 * return (
 *   <LoginForm formObj={formObj}/>
 * )
 * 
 * @param {object} formObj - A form to parse
 * @class LoginForm
 */
const LoginForm = ({ formObj }) => {

    /** @member {object} - Class styles */
    const classes = useStyles();

    /** @constant {object} - Define max dimensione for small screen */
    const small = useMedia("(max-width: 900px)");
    const medium = useMedia("(max-width: 1300px)");

    return (
        <>
            <div className={classes.headerBox}>
                <h4 style={{ textAlign: 'center', marginBottom: '3vh' }}> Login Form </h4>
                <div className={small ? classes.loginBoxSmall : medium ? classes.loginBoxMedium : classes.loginBoxLarge}>
                    <Form onSubmit={formObj.handleSubmit} className={classes.formContainer}>
                        <Form.Group className={classes.myForm} controlId="formBasicEmail">
                            <Form.Label>Indirizzo email</Form.Label>
                            <Form.Control type="email"
                                placeholder="Inserire una email"
                                name="email"
                                onBlur={formObj.handleBlur}
                                value={formObj.values.email}
                                onChange={formObj.handleChange} />
                            {formObj.touched.email && formObj.errors.email ? (
                                <div className={classes.errorMessage}>{formObj.errors.email}</div>
                            ) : null}
                        </Form.Group>

                        <Form.Group className={classes.myForm} controlId="formBasicPassword">
                            <Form.Label>Password</Form.Label>
                            <Form.Control type="password"
                                name="password"
                                placeholder="Inserire una password"
                                onBlur={formObj.handleBlur}
                                value={formObj.values.password}
                                onChange={formObj.handleChange} />
                            {formObj.touched.password && formObj.errors.password ? (
                                <div className={classes.errorMessage}>{formObj.errors.password}</div>
                            ) : null}
                        </Form.Group>

                        <Button type="submit" className={classes.myFormButton} variant="dark" block>
                            Login
                    </Button>
                    </Form>
                </div>
            </div>
        </>
    );
}

export default LoginForm;