import React, { createContext, useState } from 'react';

export const UserContext = createContext();

/**
 * UserContextProvider
 * containing data related to user. If logged in or not
 * @class UserContextProvider
 */
const UserContextProvider = (props) => {
    const [isLoggedIn, setIsLoggedIn] = useState(false);

    return (
        <UserContext.Provider
            value={{
                isLoggedIn,
                setIsLoggedIn,
            }}>
            {props.children}
        </UserContext.Provider>
    );
};

export default UserContextProvider;
