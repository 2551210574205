import React, { useState, useEffect } from 'react';
import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card';
import { createUseStyles } from 'react-jss';
import ListGroup from 'react-bootstrap/ListGroup';
import Form from 'react-bootstrap/Form';
import {
    BsChevronDown
} from 'react-icons/bs';
import {
    BsChevronUp
} from 'react-icons/bs';

/**
 * Local css styles
 * @constant {object}
 */
const useStyles = createUseStyles({
    myAccordion: {
        width: '50vw'
    },
    myFormLabel:
    {
        width: '25vw'
    },
    myFormcheck:
    {
        marginLeft: '5vw'
    }
})

/**
 * Component used in order to display the server flags.
 * 
 * @param {object} formObj is the form object get by InsertLicence form 
 * @class CollapsibleServer
 */
const CollapsibleServer = ({ formObj }) => {

    /** @member {boolean} - expanded mode toggle */
    const [expanded, setExpanded] = useState(false);

    /** @member {object} - Class styles */
    const classes = useStyles();

    // handling accordion
    useEffect(() => {
    }, [expanded]);

    const selectedAccordion = () => {
        setExpanded(!expanded);
    }

    /**
     * change icon on expand event
     */
    function iconChange() {
        if (expanded) {
            return (<BsChevronUp style={{ marginRight: '10px' }} />);
        } else {
            return (<BsChevronDown style={{ marginRight: '10px' }} />);
        }
    }

    return (
        <Accordion onSelect={selectedAccordion} >
            <Card>
                <Card.Header variant="primary" >
                    <Accordion.Toggle style={{ border: '1px solid #D3D3D3', backgroundColor: 'rgba(0, 0, 0, 0)', color: 'black' }} as={Card.Header} eventKey="0">
                        {iconChange()}
                        Server Flags
                    </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="0">
                    <Card.Body>
                        <ListGroup.Item className={classes.myAccordion}  >
                            <Form.Label className={classes.myFormLabel}>
                                Licenza DCC Server
                            </Form.Label>
                            <Form.Check className={classes.myFormcheck}
                                inline
                                type='checkbox'
                                name="flag0"
                                value={formObj.values.flag0}
                                onBlur={formObj.handleBlur}
                                onChange={formObj.handleChange}
                                checked={formObj.values.flag0} />
                        </ListGroup.Item>
                        <ListGroup.Item className={classes.myAccordion}  >
                            <Form.Label className={classes.myFormLabel}>
                                Gestione polling di stato
                            </Form.Label>
                            <Form.Check className={classes.myFormcheck}
                                inline
                                type='checkbox'
                                name="flag1"
                                value={formObj.values.flag1}
                                onBlur={formObj.handleBlur}
                                onChange={formObj.handleChange}
                                checked={formObj.values.flag1} />
                        </ListGroup.Item>
                        <ListGroup.Item className={classes.myAccordion}  >
                            <Form.Label className={classes.myFormLabel}>
                                Conversione configurazione da vecchio premium
                            </Form.Label>
                            <Form.Check className={classes.myFormcheck}
                                inline
                                type='checkbox'
                                name="flag2"
                                value={formObj.values.flag2}
                                onBlur={formObj.handleBlur}
                                onChange={formObj.handleChange}
                                checked={formObj.values.flag2} />
                        </ListGroup.Item>
                        <ListGroup.Item className={classes.myAccordion}  >
                            <Form.Label className={classes.myFormLabel}>
                                Supporto monitor videowall
                            </Form.Label>
                            <Form.Check className={classes.myFormcheck}
                                inline
                                type='checkbox'
                                name="flag3"
                                value={formObj.values.flag3}
                                onBlur={formObj.handleBlur}
                                onChange={formObj.handleChange}
                                checked={formObj.values.flag3} />
                        </ListGroup.Item>
                        <ListGroup.Item className={classes.myAccordion}  >
                            <Form.Label className={classes.myFormLabel}>
                                Gestione mappe
                            </Form.Label>
                            <Form.Check className={classes.myFormcheck}
                                inline
                                type='checkbox'
                                name="flag4"
                                value={formObj.values.flag4}
                                onBlur={formObj.handleBlur}
                                onChange={formObj.handleChange}
                                checked={formObj.values.flag4} />
                        </ListGroup.Item>
                        <ListGroup.Item className={classes.myAccordion}  >
                            <Form.Label className={classes.myFormLabel}>
                                Licenza server di backup
                            </Form.Label>
                            <Form.Check className={classes.myFormcheck}
                                inline
                                type='checkbox'
                                name="flag5"
                                value={formObj.values.flag5}
                                onBlur={formObj.handleBlur}
                                onChange={formObj.handleChange}
                                checked={formObj.values.flag5} />
                        </ListGroup.Item>
                        <ListGroup.Item className={classes.myAccordion}  >
                            <Form.Label className={classes.myFormLabel}>
                                Flag 6
                            </Form.Label>
                            <Form.Check className={classes.myFormcheck}
                                inline
                                type='checkbox'
                                name="flag6"
                                value={formObj.values.flag6}
                                onBlur={formObj.handleBlur}
                                onChange={formObj.handleChange}
                                checked={formObj.values.flag6} />
                        </ListGroup.Item>
                        <ListGroup.Item className={classes.myAccordion} >
                            <Form.Label className={classes.myFormLabel}>
                                Flag 7
                            </Form.Label>
                            <Form.Check
                                className={classes.myFormcheck}
                                inline
                                type='checkbox'
                                name="flag7"
                                value={formObj.values.flag7}
                                onBlur={formObj.handleBlur}
                                onChange={formObj.handleChange}
                                checked={formObj.values.flag7} />
                        </ListGroup.Item>
                    </Card.Body>
                </Accordion.Collapse>
            </Card>
        </Accordion>
    );
}

export default CollapsibleServer;