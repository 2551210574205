import React, { createContext, useState } from 'react';

export const LicenceContext = createContext();


/**
 * LicenceContextProvider
 * containing the licenceList and functions to process this array or filter through it
 * @class LicenceContextProvider
 */
const LicenceContextProvider = (props) => {
    const [licenceList, setLicenceList] = useState(
        [
        ]
    );

    const processListArray = (licenceArray) => {
        if (licenceArray !== null && licenceArray !== undefined && licenceArray.length > 0) {
            var licences = [];
            licenceArray.forEach((licence) => {
                var creationDate = licence.creationDate.split('T');
                licence.creationDate = creationDate[0];
                licences.push(licence);
            });
            setLicenceList(licences);
        } else {
            setLicenceList([]);
        }
    }

    const filterLicence = (serial) => {
        var res = licenceList.find((item) => {
            return item.serial === serial;
        });
        return res;
    };

    return (
        <LicenceContext.Provider
            value={{
                licenceList,
                setLicenceList,
                processListArray,
                filterLicence
            }}>
            {props.children}
        </LicenceContext.Provider>
    );
};

export default LicenceContextProvider;
