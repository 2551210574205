import useMedia from '../hooks/useMedia';
import { createUseStyles } from 'react-jss'
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { Link, Route, Redirect } from "react-router-dom";
import { useContext, useEffect } from 'react';
import { UserContext } from '../context/UserContext';
import InsertLicence from '../views/InsertLicence';
import ListLicence from '../views/ListLicence';
import Login from '../views/Login';
import { useHistory } from "react-router-dom";
import logo from '../assets/LOGO_TECHBOARD.png';

/**
 * Local css styles
 * @constant {object}
 */
const useStyles = createUseStyles({
    myNav:
    {
        height: '6vh',
        backgroundColor: '#2F4F4F',
        backgroundImage: 'linear-gradient(to right, #ffffff, #A9A9A9)'
    },
    myLinkContainer:
    {
        border: '2px solid black'
    },
    myLink:
    {
        marginLeft: '2vw',
        marginRight: '2vw',
        color: 'black',
        textDecoration: 'none',
    },
});

/**
 * Part between header and main body, which is responsible for navigation
 *
 * @component
 * @class NavigationHeader
 */
const NavigationHeader = () => {

    /** @member {object} - hook small media query object */
    const small = useMedia("(max-width: 600px)");
    /** @member {object} - hook medium media query object */
    const medium = useMedia("(max-width: 1600px)");

    /** @member {object} - History  */
    const history = useHistory();

    /** @member {boolean} - Check if user login */
    const { isLoggedIn, setIsLoggedIn } = useContext(UserContext)

    /** @member {object} - Class styles */
    const classes = useStyles();

    //retrive token stored
    useEffect(() => {
        const loggedInUser = localStorage.getItem("token");
        if (loggedInUser) {
            setIsLoggedIn(true);
        }
    }, [])

    /**
     * Logout method
     * @param {*} 
     */
    const logout = () => {
        setIsLoggedIn(false);
        history.push("/");
        localStorage.clear();
    }

    return (
        <>
            <Navbar className={classes.myNav} >
                <Navbar.Brand >
                    <img
                        alt="logo"
                        src={logo}
                        style={{ height: '4vh', width: small ? '30vw' : medium ? '20vw' : '15vw', marginRight: '5vw' }}
                        className="d-inline-block align-top"
                    />
                </Navbar.Brand>
                <Nav className="mr-auto">
                    {isLoggedIn &&
                        <>
                            <Link className={classes.myLink} to="/list">Lista licenze</Link>
                            <Link className={classes.myLink} to="/insert">Inserisci licenza</Link>
                            <Link onClick={logout} className={classes.myLink} to="/">Logout</Link>
                        </>
                    }
                </Nav>
            </Navbar>

            <Route exact path="/" render={() => (
                isLoggedIn ?
                    (
                        <Redirect to="/list" />
                    )
                    :
                    (
                        <Login />
                    )
            )} />
            <Route exact path="/list" render={() => (
                !isLoggedIn ?
                    (
                        <Redirect to="/" />
                    )
                    :
                    (
                        <ListLicence />
                    )
            )} />
            <Route exact path="/insert"
                render={() => (
                    !isLoggedIn ?
                        (
                            <Redirect to="/" />
                        )
                        :
                        (
                            <InsertLicence />
                        )
                )} />
            <Route exact path="/insert/:serial"
                render={() => (
                    !isLoggedIn ?
                        (
                            <Redirect to="/" />
                        )
                        :
                        (
                            <InsertLicence />
                        )
                )} />
        </>
    );
}

export default NavigationHeader;

