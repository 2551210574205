/**
 * @file In this file are presents all enum
 */

/**
 * Enumerative for determine the license type
 * { "usb": 1, "activation": 2 }
 * @constant
 * @type {object}
 * @default
*/
export const typeMachine = Object.freeze({ "usb": 1, "activation": 2 })