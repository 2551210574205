import { useEffect, useState } from 'react';

/**
 * Class for media query managing, for keep the site responsive
 * @param {object} query
 * @class useMedia
 */
function useMedia(query) {
    const [matches, setMatches] = useState(
        window.matchMedia(query).matches
    );

    // Activity normally for componentDidMount + componentDidUpdate
    useEffect(() => {
        const media = window.matchMedia(query);
        if (media.matches !== matches) {
            setMatches(media.matches);
        }

        const listener = () => setMatches(media.matches);
        media.addListener(listener);

        return () => media.removeListener(listener);
    }, [query, matches]);

    // publish value for render
    return matches;
}

export default useMedia;