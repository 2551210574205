
import useMedia from '../hooks/useMedia';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import { createUseStyles } from 'react-jss';
import { Globe, Facebook, Linkedin, Youtube } from 'react-bootstrap-icons';

/**
 * Local css styles
 * @constant {object}
 */
const useStyles = createUseStyles({
    myFooter: {
        height: '8vh',
        backgroundColor: '#008B8B',
        color: 'black'
    },
    myCol: {
        width: '33vw',
        textAlign: 'center',
    },
    myRow: {
        justifyContent: 'center',
        fontSize: '0.9em'
    },
    myRowLarge: {
        justifyContent: 'center',
        fontSize: '1.1em'
    },
    iconContainer:
    {
        margin: '1vw'
    },
    btnContainer:
    {
        borderColor: "#008B8B",
        padding: 0,
        color: "black",
        backgroundColor: "#008B8B"
    },
    iconListContainerSmall:
    {
        justifyContent: 'center',
        display: 'flex',
        flexDirection: 'row',
    },
    iconListContainerMedium:
    {
        justifyContent: 'center',
        display: 'flex',
        flexDirection: 'row',
        marginTop: '-0.5vh'
    },
    iconListContainerBig:
    {
        justifyContent: 'center',
        display: 'flex',
        flexDirection: 'row',
        marginTop: '-1vh'
    }
})

/**
 * Component used for the footer 
 * @class Footer
 */
const Footer = () => {

    /** @member {object} - Class styles */
    const classes = useStyles();

    /** @member {object} - hook small media query object */
    const small = useMedia("(max-width: 900px)");
    /** @member {object} - hook medium media query object */
    const medium = useMedia("(max-width: 1600px)");
    /** @member {object} - hook big media query object */
    const big = useMedia("(max-width: 3000px)");

    /**
     * go to facebook page
     */
    const gotoFacebook = () => {
        window.location.href = 'https://www.facebook.com/techboardgroup';
    }

    /**
     * go to youtube page 
     */
    const gotoYoutube = () => {
        window.location.href = 'https://www.youtube.com/channel/UCPXJfCJtiUnVACI7EXWcqeQ';
    }

    /**
     * go to linkedin page 
     */
    const gotoLinkedin = () => {
        window.location.href = 'https://it.linkedin.com/company/syac-tb';
    }

    /**
     * go to website page 
     */
    const gotoWebsite = () => {
        window.location.href = 'https://www.techboardgroup.com/newsite/index.php/it/';
    }

    return (
        <Card.Footer className={classes.myFooter}>
            <Row>
                <Col className={classes.myCol}>
                    <div className={small ? classes.iconListContainerSmall : medium ? classes.iconListContainerMedium : big ? classes.iconListContainerBig : classes.iconListContainerSmall}>
                        <div className={classes.iconContainer}>
                            <Button onClick={gotoWebsite} className={classes.btnContainer}>
                                <Globe size={'1.5em'} />
                            </Button>
                        </div>
                        <div className={classes.iconContainer}>
                            <Button onClick={gotoYoutube} className={classes.btnContainer}>
                                <Youtube size={'1.5em'} />
                            </Button>
                        </div>
                        <div className={classes.iconContainer}>
                            <Button onClick={gotoLinkedin} className={classes.btnContainer}>
                                <Linkedin size={'1.5em'} />
                            </Button>
                        </div>
                        <div className={classes.iconContainer}>
                            <Button onClick={gotoFacebook} className={classes.btnContainer}>
                                <Facebook size={'1.5em'} />
                            </Button>
                        </div>
                    </div>
                </Col>
                <Col className={classes.myCol}>
                    <Row className={small ? classes.myRow : classes.myRowLarge}>Via della Scienza, 50, MO</Row>
                    <Row className={small ? classes.myRow : classes.myRowLarge}>Telefono +39 059 289861</Row>
                </Col>
            </Row>
        </Card.Footer >
    );
}

export default Footer;