import Navbar from 'react-bootstrap/Navbar';
import { createUseStyles } from 'react-jss';

/**
 * Local css styles
 * @constant {object}
 */
const useStyles = createUseStyles({
    myNav: {
        height: '8vh',
        backgroundColor: '#008B8B'
    }
})

/**
 * Highest visible part on the website
 *
 * @component
 * @example
 * return (
 *   <Header />
 * )
 * @class Header
 */
const Header = () => {

    /** @member {object} - Class styles */
    const classes = useStyles();

    return (
        <div>
            <Navbar className={classes.myNav} variant="dark">
                <Navbar.Brand style={{ color: 'black', fontWeight: 'bold' }} className='m-auto' href="#home">Digieye USB Licenze</Navbar.Brand>
            </Navbar>
        </div>
    );
}

export default Header;