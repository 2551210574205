import FileDownload from 'js-file-download'
import { store } from 'react-notifications-component';
import { setNotificationObject, notificationMessage } from '../globals/NotificationConfig';
import axios from 'axios';
import { useContext, useEffect, useState } from 'react';
import { createUseStyles } from 'react-jss';
import BootstrapTable from 'react-bootstrap-table-next';
import Button from 'react-bootstrap/Button';
import { useHistory } from "react-router-dom";
import paginationFactory from 'react-bootstrap-table2-paginator';
import { LicenceContext } from '../context/LicenceContext';
import { tokenGenerate } from '../globals/TokenHandler'
import { baseApiUrl, licenceApiUrl, downloadLicenceUrl } from '../globals/ApiConfig';
import { axiosTimeout } from '../globals/GlobalConfig';
import logout from '../globals/AuthenticationHandler';
import { UserContext } from '../context/UserContext';
import {
    StatusCodes,
} from 'http-status-codes';
import { typeMachine } from '../globals/enumerative';
import Modal from 'react-bootstrap/Modal';


/**
 * Local css styles
 * @constant {object}
 */
const useStyles = createUseStyles({
    myBody:
    {
        height: '78vh',
        overflowY: 'auto'
    },
    myTable:
    {
        minWidth: '950px',
        marginTop: '2vh',
        marginLeft: '2vw',
        marginRight: '2vw',
    }
});

/**
 * Page for listing licences
 * @class ListLicence
 */
const ListLicence = () => {

    /** @member {boolean} - check if user login */
    const { licenceList, processListArray } = useContext(LicenceContext);

    /** @member {boolean} - show or hide delete modal  */
    const [showDeleteModal, setShowDeleteModal] = useState(false);

    /** @member {object} - pass row object to delete Modal  */
    const [selectedRow, setSelectedRow] = useState({});

    /** @member {object} - Class styles */
    const classes = useStyles();

    /** @member {object} - history  */
    const history = useHistory();


    const { isLoggedIn, setIsLoggedIn } = useContext(UserContext)

    // execute on startup or on licence new
    useEffect(() => {
        getLicences();
    }, [isLoggedIn]);


    /**
     * Load all licences
     */
    const getLicences = () => {
        const token = tokenGenerate(localStorage.getItem('email'));
        const config = {
            headers: {
                Authorization: `Bearer ${token}`,
                email: localStorage.getItem('email')
            }
        };
        axios.get(baseApiUrl + licenceApiUrl, config, { timeout: axiosTimeout })
            .then((result) => {
                if (StatusCodes.OK === result.status) {
                    processListArray(result.data);
                }
            })
            .catch((err) => {
                if (err.response.status === StatusCodes.FORBIDDEN) {
                    logout(setIsLoggedIn);
                } else {
                    const notification = setNotificationObject(notificationMessage.errorString, notificationMessage.downloadErrorString, notificationMessage.dangerString);
                    store.addNotification(notification);
                }
            });
    }

    /**
     * Go to page 
     * @param {*} row - go at licence
     */
    function gotoPage(row) {
        history.push("/insert/" + row.serial);
    }

    /**
     * Download selected licence
     * @param {*} row - actual selected
     */
    function download(row) {
        if (row.serial !== undefined && row.serial !== null) {
            const serialVal = row.serial;
            axios.get(baseApiUrl + licenceApiUrl + downloadLicenceUrl + serialVal)
                .then((result) => {
                    if (StatusCodes.OK === result.status) {
                        FileDownload(result.data, 'DCCPLicence.lic');
                        const notification = setNotificationObject(notificationMessage.okString, notificationMessage.downloadSuccessString, notificationMessage.successString);
                        store.addNotification(notification);
                    } else {
                        const notification = setNotificationObject(notificationMessage.errorString, notificationMessage.downloadErrorString, notificationMessage.dangerString);
                        store.addNotification(notification);
                    }
                })
                .catch((err) => {
                    const notification = setNotificationObject(notificationMessage.errorString, notificationMessage.downloadErrorString, notificationMessage.dangerString);
                    store.addNotification(notification);
                })
        } else {
            const notification = setNotificationObject(notificationMessage.errorString, notificationMessage.downloadErrorString, notificationMessage.dangerString);
            store.addNotification(notification);
        }
    }

    /**
     * Delete selected licence
     * @param {*} row - 
     */
    function del(row) {
        if (row.serial !== undefined && row.serial !== null) {
            const serialVal = row.serial;

            const token = tokenGenerate(localStorage.getItem('email'));
            const config = {
                headers: {
                    Authorization: `Bearer ${token}`,
                    email: localStorage.getItem('email')
                }
            };
            axios.delete(baseApiUrl + licenceApiUrl + serialVal, config, { timeout: axiosTimeout })
                .then((result) => {
                    getLicences();

                    if (StatusCodes.OK === result.status) {
                        const notification = setNotificationObject(notificationMessage.okString, notificationMessage.licenceSuccessString, notificationMessage.successString);
                        store.addNotification(notification);
                    } else {
                        const notification = setNotificationObject(notificationMessage.errorString, notificationMessage.licenceErrorString, notificationMessage.dangerString);
                        store.addNotification(notification);
                    }
                })
                .catch((err) => {
                    const notification = setNotificationObject(notificationMessage.errorString, notificationMessage.licenceErrorString, notificationMessage.dangerString);
                    store.addNotification(notification);
                })
        } else {
            const notification = setNotificationObject(notificationMessage.errorString, notificationMessage.licenceErrorString, notificationMessage.dangerString);
            store.addNotification(notification);
        }
    }

    /**
     * Function to create the download button based on the type of license
     * @function linkFollow
     * @param {*} cell - unused
     * @param {*} row - row reference
     * @param {*} rowIndex  - unused
     * @param {*} formatExtraData  - unused
     * @returns Button
     */
    const linkFollow = (cell, row, rowIndex, formatExtraData) => {
        return (
            <div style={{ display: 'float', flexDirection: 'row' }}>
                <Button variant="dark"
                    onClick={() => gotoPage(row)}
                >
                    Modifica
            </Button>
                {row.type === typeMachine.activation &&
                    <Button style={{ marginLeft: '1vw' }} variant="dark"
                        onClick={() => download(row)}
                    >
                        Download
                    </Button>
                }
                <Button style={{ marginLeft: '1vw' }} variant="danger"
                    onClick={() => {
                        setShowDeleteModal(true);
                        setSelectedRow(row);
                    }}
                >
                    Elimina
                </Button>
            </div >
        );
    };

    const getEnumVal = (value) => {
        if (value === typeMachine.usb) {
            return "Usb";
        }
        if (value === typeMachine.activation) {
            return "Activation key";
        }
    }

    const options = {
        onSizePerPageChange: (sizePerPage, page) => {
        },
        onPageChange: (page, sizePerPage) => {
        }
    };

    const columns = [{
        dataField: 'serial',
        text: 'Codice macchina',
        sort: true,
        style: {
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            maxWidth: '25vw'
        }
    },
    {
        dataField: 'creationDate',
        text: 'Data',
        sort: true,
        style: {
            maxWidth: '15vw'
        }
    },
    {
        dataField: 'type',
        text: 'Tipo',
        sort: true,
        formatter: (cellContent, row) => {
            const val = getEnumVal(cellContent);
            return (<> {val} </>);
        },
        style: {
            maxWidth: '10vw'
        }
    },
    {
        dataField: 'note',
        text: 'Cliente',
        style: {
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            maxWidth: '20vw'
        }
    },
    {
        formatter: linkFollow,
        text: 'Seleziona',
        headerStyle: (colum, colIndex) => {
            return { maxWidth: '35vw' };
        }
    }];


    /**
     * Function for close deleting modal
     * @function handleDeleteModalClose
     */
    const handleDeleteModalClose = () => {
        setShowDeleteModal(false);
        setSelectedRow(null);
    }

    /**
     * Function for deleting selected row
     * @function handleDeleteModalClose
     */
    const handleDeleteModalDelete = () => {
        setShowDeleteModal(false);
        del(selectedRow);
    }


    return (
        <div className="ListLicence">

            <Modal show={showDeleteModal} onHide={handleDeleteModalClose} animation={false}>
                <Modal.Header closeButton>
                    <Modal.Title>Attenzione!</Modal.Title>
                </Modal.Header>
                <Modal.Body>Sei sicuro di voler cancellare la licenza?</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleDeleteModalClose}>
                        Chidi
                    </Button>
                    <Button variant="danger" onClick={handleDeleteModalDelete}>
                        Elimina
                    </Button>
                </Modal.Footer>
            </Modal>

            <div className={classes.myBody}>

                <div className={classes.myTable}>
                    {licenceList.length === 0 &&
                        (
                            <>
                                <p> Al momento non ci sono licenze inserite, appena ce ne saranno verranno generate le righe della tabella </p>
                                <BootstrapTable
                                    bootstrap4
                                    keyField="serial"
                                    style={{ minWidth: '1000px' }}
                                    data={licenceList}
                                    columns={columns}
                                    pagination={paginationFactory(options)}
                                />
                            </>
                        )}
                    {licenceList.length > 0 &&
                        (
                            <>
                                <BootstrapTable
                                    bootstrap4
                                    keyField="serial"
                                    wrapperClasses="table-responsive"
                                    data={licenceList}
                                    columns={columns}
                                    pagination={paginationFactory(options)}
                                />
                            </>
                        )}
                </div>

            </div>

        </div>
    );
}

export default ListLicence;