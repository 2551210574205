
import { store } from 'react-notifications-component';
import { setNotificationObject, notificationMessage } from '../globals/NotificationConfig';

/**
 * function used to logout users
 * 
 * @param {object} setIsLoggedIn used in order to change the loggedIn status on UserContext
 */
export default function logout(setIsLoggedIn) {
    localStorage.removeItem('email');
    localStorage.removeItem('token');
    const notification = setNotificationObject(notificationMessage.errorString, notificationMessage.loginExpiredString, notificationMessage.dangerString);
    store.addNotification(notification);
    setIsLoggedIn(false);
}