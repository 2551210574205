/**
 * @file In this file are presents all constant to use for general purpose
 */


/**
 * Notification time after action performed
 * @exports notificationTime
 */
export const notificationTime = 1500;

/**
 * Net timeout
 * @exports axiosTimeout
 */
export const axiosTimeout = 1000;