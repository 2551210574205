import React, { useState, useEffect } from 'react';
import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card';
import { createUseStyles } from 'react-jss';
import ListGroup from 'react-bootstrap/ListGroup';
import Form from 'react-bootstrap/Form';
import {
    BsChevronDown
} from 'react-icons/bs';
import {
    BsChevronUp
} from 'react-icons/bs';

/**
 * Local css styles
 * @constant {object}
 */
const useStyles = createUseStyles({
    myAccordion: {
        width: '50vw'
    },
    myFormLabel:
    {
        width: '25vw'
    },
    myFormcheck:
    {
        marginLeft: '5vw'
    }
});

/**
 * Component used in order to display the videowall flags.
 * 
 * @param {object} formObj is the form object get by InsertLicence form 
 * @class CollapsibleVideowall
 */
const CollapsibleVideowall = ({ formObj }) => {

    /** @member {boolean} - expanded mode toggle */
    const [expanded, setExpanded] = useState(false);

    /** @member {object} - Class styles */
    const classes = useStyles();

    // handling accordion
    useEffect(() => {

    }, [expanded]);

    const selectedAccordion = () => {
        setExpanded(!expanded);
    }


    /**
     * change icon on expand event
     */
    function iconChange() {
        if (expanded) {
            return (<BsChevronUp style={{ marginRight: '10px' }} />);
        } else {
            return (<BsChevronDown style={{ marginRight: '10px' }} />);
        }
    }

    return (
        <Accordion onSelect={selectedAccordion}>
            <Card>
                <Card.Header variant="primary" >
                    <Accordion.Toggle style={{ border: '1px solid #D3D3D3', backgroundColor: 'rgba(0, 0, 0, 0)', color: 'black' }} as={Card.Header} eventKey="0">
                        {iconChange()}
                        Videowall Flags
                    </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="0">
                    <Card.Body>
                        <ListGroup.Item className={classes.myAccordion}  >
                            <Form.Label className={classes.myFormLabel}>
                                Licenza DCC Videowall
                            </Form.Label>
                            <Form.Check className={classes.myFormcheck}
                                inline
                                type='checkbox'
                                name="flag16"
                                value={formObj.values.flag16}
                                onBlur={formObj.handleBlur}
                                onChange={formObj.handleChange}
                                checked={formObj.values.flag16} />
                        </ListGroup.Item>
                        <ListGroup.Item className={classes.myAccordion}  >
                            <Form.Label className={classes.myFormLabel}>
                                Flag 17
                            </Form.Label>
                            <Form.Check className={classes.myFormcheck}
                                inline
                                type='checkbox'
                                name="flag17"
                                value={formObj.values.flag17}
                                onBlur={formObj.handleBlur}
                                onChange={formObj.handleChange}
                                checked={formObj.values.flag17} />
                        </ListGroup.Item>
                        <ListGroup.Item className={classes.myAccordion} >
                            <Form.Label className={classes.myFormLabel}>
                                Flag 18
                            </Form.Label>
                            <Form.Check className={classes.myFormcheck}
                                inline
                                type='checkbox'
                                name="flag18"
                                value={formObj.values.flag18}
                                onBlur={formObj.handleBlur}
                                onChange={formObj.handleChange}
                                checked={formObj.values.flag18} />
                        </ListGroup.Item>
                        <ListGroup.Item className={classes.myAccordion}  >
                            <Form.Label className={classes.myFormLabel}>
                                Flag 19
                            </Form.Label>
                            <Form.Check className={classes.myFormcheck}
                                inline
                                type='checkbox'
                                name="flag19"
                                value={formObj.values.flag19}
                                onBlur={formObj.handleBlur}
                                onChange={formObj.handleChange}
                                checked={formObj.values.flag19} />
                        </ListGroup.Item >
                        <ListGroup.Item className={classes.myAccordion}  >
                            <Form.Label className={classes.myFormLabel}>
                                Flag 20
                            </Form.Label>
                            <Form.Check className={classes.myFormcheck}
                                inline
                                type='checkbox'
                                name="flag20"
                                value={formObj.values.flag20}
                                onBlur={formObj.handleBlur}
                                onChange={formObj.handleChange}
                                checked={formObj.values.flag20} />
                        </ListGroup.Item >
                        <ListGroup.Item className={classes.myAccordion}  >
                            <Form.Label className={classes.myFormLabel}>
                                Flag 21
                            </Form.Label>
                            <Form.Check className={classes.myFormcheck}
                                inline
                                type='checkbox'
                                name="flag21"
                                value={formObj.values.flag21}
                                onBlur={formObj.handleBlur}
                                onChange={formObj.handleChange}
                                checked={formObj.values.flag21} />
                        </ListGroup.Item >
                        <ListGroup.Item className={classes.myAccordion}  >
                            <Form.Label className={classes.myFormLabel}>
                                Flag 22
                            </Form.Label>
                            <Form.Check className={classes.myFormcheck}
                                inline
                                type='checkbox'
                                name="flag22"
                                value={formObj.values.flag22}
                                onBlur={formObj.handleBlur}
                                onChange={formObj.handleChange}
                                checked={formObj.values.flag22} />
                        </ListGroup.Item >
                        <ListGroup.Item className={classes.myAccordion}  >
                            <Form.Label className={classes.myFormLabel}>
                                Flag 23
                            </Form.Label>
                            <Form.Check
                                className={classes.myFormcheck}
                                inline
                                type='checkbox'
                                name="flag23"
                                value={formObj.values.flag23}
                                onBlur={formObj.handleBlur}
                                onChange={formObj.handleChange}
                                checked={formObj.values.flag23} />
                        </ListGroup.Item >
                    </Card.Body >
                </Accordion.Collapse >
            </Card >
        </Accordion >
    );
}

export default CollapsibleVideowall;