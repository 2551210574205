
var crypto = require('crypto');

/**
 * function used to generate a token
 * 
 * @param {string} email this is the email used to encrypt data and generate the token 
 */
export const tokenGenerate = (email) => {
    var key = email + "_" + new Date().toISOString().slice(0, 10)
    var hash = crypto.createHash('md5').update(key).digest("hex");
    return hash;
}