/**
 * @file In this file are presents all constant to use for get data from server
 */


/**
 * Server URL
 * @exports baseApiUrl
 */
export const baseApiUrl = "https://licenze.syac.it:8080/";

/**
 * Base user resources
 * @exports userApiUrl
 */
export const userApiUrl = "api/user";

/**
 * Base licence resources
 * @exports licenceApiUrl
 */
export const licenceApiUrl = "api/licence/";

/**
 * Base activation key resources
 * @exports downloadLicenceUrl
 */
export const downloadLicenceUrl = "activation-key/";

/**
 * Resorce for refreshing data
 * @exports refreshLicenceUrl
 */
export const refreshLicenceUrl = "/refresh";